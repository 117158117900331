<!-- =========================================================================================
	File Name: ECommerceCheckout.vue
	Description: eCommerce Checkout page
	----------------------------------------------------------------------------------------
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Author: Pixinvent
	Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="ecommerce-checkout-demo" class="shop-wrapper">
    <div class="w-full flex flex-col lg:flex-row md:flex-row">
      <div class="w-full lg:w-3/5 md:w-3/5">
        <Tabs :onClick="onClick" :active="active" />
        <div class="py-4">
          <form-wizard ref="checkoutWizard" color="rgba(var(--vs-primary), 1)" :title="null" :subtitle="null"
            :hide-buttons="true">
            <!-- STORE LIMITATION -->
            <div>
              <vs-card :key="limitation._id" v-for="limitation in limitationsList ">
                <!-- <div class="flex justify-between">
                    <span class="text-base lg:text-lg md:text-lg font-semibold">{{limitation.limitation_name}}</span>
                    <span class="font-semibold">{{`min quantity : ${limitation.min_qty} - max quantity : ${limitation.max_qty}`}}</span>
                  </div>
                  <p class="text-base">Product/s</p>
                  <p class="text-base" :key="product._id"  v-for="product in limitation.products">{{product.product?product.product.name:""}}</p> -->
                <p v-if="limitation.isAboveMax" class=" text-base text-danger">{{ limitation.display_message_max }}</p>
                <p v-if="limitation.isBelowMin" class=" text-base text-danger">{{ limitation.display_message_min }}</p>
                <p v-if="limitation.isBellowAmount" class="text-base text-danger">
                  {{ limitation.display_purchase_message_min }}
                </p>
              </vs-card>
            </div>
            <!-- STORE LIMITATION -->
            <div v-if="active === 1">
              <div class="items-list-view" v-for="item in cartItems" :key="item._id">
                <checkout-list-view :item="item" class="mb-4">
                  <!-- SLOT: ITEM META -->
                  <template slot="remove">
                    <div
                        class="w-1/2 lg:w-32 flex items-center justify-center"
                        >
                        <x-icon size="1.5x" class="cursor-pointer" @click="removeItemFromCart(item)"></x-icon>
                      </div>
                  </template>
                  <template slot="item-meta">
                    <h6 class="
                        item-name
                        font-semibold
                        mb-1
                        cursor-pointer
                        hover:text-primary
                      " @click="
                        $router
                          .push({
                            name: 'ecommerce-item-detail-view',
                            params: { item_id: item._id },
                          })
                          .catch(() => { })
                      ">
                      {{ item.name }}
                    </h6>
                    <p class="text-sm mb-2">
                      By
                      <span class="font-semibold cursor-pointer">{{
                          item.brand
                      }}</span>
                    </p>
                    <p class="text-success text-sm">In Stock</p>

                    <br />
                    <span class="text-danger" style="font-size: 0.75em">{{
                        errors.firstById(item._id)
                    }}</span>
                  </template>

                  <!-- SLOT: ACTION BUTTONS -->
                  <template slot="action-buttons">
                    <div class="w-full lg:w-auto flex flex-row lg:flex-col justify-end items-end lg:justify-none ">
                      <!-- <div class="w-1/2 lg:w-32 flex flex-row increment-buttons">
                        <div class="
                            w-1/3
                            flex
                            justify-center
                            items-center
                            text-4xl text-primary
                            cursor-pointer
                          " @click="decrease(item._id)">
                          <div>-</div>
                        </div>
                        <div class="w-1/3 flex justify-center items-center text-lg">
                          <div>{{ item.quantity }}</div>
                        </div>
                        <div class="
                            w-1/3
                            flex
                            justify-center
                            items-center
                            text-4xl text-primary
                            cursor-pointer
                          " @click="increase(item._id)">
                          <div>+</div>
                        </div>
                      </div> -->

                      <vs-input-number
                        v-validate="'required'"
                        class="w-full"
                        max="999"
                        min="1"
                        :is-disabled="disableInput"
                        :disabled="disableInput"
                        v-model="item.quantity"
                        type="number"
                        @input="increase(item)"
                        @change="increase(item)"
                        @focusout = "item.quantity = item.quantity|| 1"
                      />
                      <!-- PRIMARY BUTTON: REMOVE -->
                      <!-- <div class="w-1/2 lg:w-32 p-3 cursor-pointer text-danger border text-center mt-3 bg-red-100 lg:bg-white hover:bg-red-100 remove-button " @click="removeItemFromCart(item)">
                        Remove
                      </div> -->
                    </div>
                  </template>
                </checkout-list-view>
              </div>
            </div>
            <div v-if="active === 2">
              <vx-card title="Shipping Details" subtitle="" class="mb-base">
                <form data-vv-scope="add-new-address">
                  <p class="pb-2 font-semibold">Delivery Address</p>
                  <p>{{ shippingDetails.address.displayAddress }}</p>
                  <p class="py-5 font-semibold">Hours of operation</p>
                  <div class="w-full sm:w-1/2">
                    <div class="vx-row">
                      <div class="vx-col sm:w-1/5 w-full">
                        <p class="pt-2">Monday</p>
                      </div>
                      <div class="vx-col sm:w-4/5 w-full">
                        <vs-input v-validate="'required'" data-vv-as="field" name="monday" class="w-full"
                          placeholder="9-5 or closed" v-model="operations.monday" />
                      </div>
                    </div>
                    <div class="vx-row mt-2">
                      <div class="vx-col sm:w-1/5 w-full">
                        <p class="pt-2">Tuesday</p>
                      </div>
                      <div class="vx-col sm:w-4/5 w-full">
                        <vs-input data-vv-as="field" name="tuesday" class="w-full" placeholder="9-5 or closed"
                          v-model="operations.tuesday" />
                      </div>
                    </div>
                    <div class="vx-row mt-2">
                      <div class="vx-col sm:w-1/5 w-full">
                        <p class="pt-2">Wednesday</p>
                      </div>
                      <div class="vx-col sm:w-4/5 w-full">
                        <vs-input data-vv-as="field" name="wednesday" class="w-full" placeholder="9-5 or closed"
                          v-model="operations.wednesday" />
                      </div>
                    </div>
                    <div class="vx-row mt-2">
                      <div class="vx-col sm:w-1/5 w-full">
                        <p class="pt-2">Thursday</p>
                      </div>
                      <div class="vx-col sm:w-4/5 w-full">
                        <vs-input data-vv-as="field" name="thursday" class="w-full" placeholder="9-5 or closed"
                          v-model="operations.thursday" />
                      </div>
                    </div>
                    <div class="vx-row mt-2">
                      <div class="vx-col sm:w-1/5 w-full">
                        <p class="pt-2">Friday</p>
                      </div>
                      <div class="vx-col sm:w-4/5 w-full">
                        <vs-input data-vv-as="field" name="friday" class="w-full" placeholder="9-5 or closed"
                          v-model="operations.friday" />
                      </div>
                    </div>
                    <div class="vx-row mt-2">
                      <div class="vx-col sm:w-1/5 w-full">
                        <p class="pt-2">Saturday</p>
                      </div>
                      <div class="vx-col sm:w-4/5 w-full">
                        <vs-input data-vv-as="field" name="saturday" class="w-full" placeholder="9-5 or closed"
                          v-model="operations.saturday" />
                      </div>
                    </div>
                    <div class="vx-row mt-2">
                      <div class="vx-col sm:w-1/5 w-full">
                        <p class="pt-2">Sunday</p>
                      </div>
                      <div class="vx-col sm:w-4/5 w-full">
                        <vs-input data-vv-as="field" name="sunday" class="w-full" placeholder="9-5 or closed"
                          v-model="operations.sunday" />
                      </div>
                    </div>
                  </div>
                  <div class="vx-row hidden">
                    <div class="vx-col sm:w-1/2 w-full">
                      <vs-input data-vv-as="field" name="clinicName" label="Clinic Name:"
                        v-model="shippingDetails.clinicName" class="w-full mt-5" disabled />
                      <!--                    <span v-show="errors.has('add-new-address.fullName')" class="text-danger">{{ errors.first('add-new-address.fullName') }}</span>-->
                    </div>

                    <div class="vx-col sm:w-1/2 w-full">
                      <vs-input name="email" label="Email:" v-model="shippingDetails.email" class="w-full mt-5"
                        disabled />
                      <!--                    <span v-show="errors.has('add-new-address.mobileNum')" class="text-danger">{{ errors.first('add-new-address.mobileNum') }}</span>-->
                    </div>
                  </div>

                  <div class="vx-row hidden">
                    <div class="vx-col sm:w-1/2 w-full">
                      <vs-input name="displayAddress" label="Address:" v-model="shippingDetails.address.displayAddress"
                        class="w-full mt-5" disabled />
                      <!--                    <span v-show="errors.has('add-new-address.houseNum')" class="text-danger">{{ errors.first('add-new-address.houseNum') }}</span>-->
                    </div>

                    <div class="vx-col sm:w-1/2 w-full">
                      <vs-input name="postCode" label="Post Code:" v-model="shippingDetails.address.postCode"
                        class="w-full mt-5" disabled />
                    </div>
                  </div>

                  <div class="vx-row hidden">
                    <div class="vx-col sm:w-1/2 w-full">
                      <vs-input v-validate="'required'" name="state" label="State:"
                        v-model="shippingDetails.address.state" class="w-full mt-5" disabled />
                    </div>

                    <div class="vx-col sm:w-1/2 w-full">
                      <vs-input name="suburb" label="Suburb:" v-model="shippingDetails.address.suburb"
                        class="w-full mt-5" disabled />
                    </div>
                  </div>

                  <p class="mt-10">
                    If you wish to change your delivery address please contact
                    fresh clinics.
                  </p>
                  <p class="mt-4">info@freshclinics.com.au</p>
                </form>
              </vx-card>
            </div>
            <div v-if="active === 3">
              <div class="relative">
                <vs-popup class="holamundo" title="Add payment method" :active.sync="popupActive">
                  <vs-tabs>
                    <vs-tab label="Credit Card" @click="paymentType = 'CreditCard'">
                      <stripe-elements ref="stripeElement" v-bind:title="saveCreditCard" :pk="stripePublishableKey"
                        :amount="totalPrice" locale="en" @token="tokenCreated" @loading="loading = $event">
                      </stripe-elements>
                      <vs-button @click="submit">Confirm And Pay</vs-button>
                    </vs-tab>
                    <vs-tab label="Bank Account" @click="paymentType = 'ClinicBankAccount'" v-if="region === 'AU'">
                      <BankSetup :totalPrice="totalPrice" :financialDetails="financialDetails"
                        :isNewClinicBankAccount="isNewClinicBankAccount" :shippingDetails="shippingDetails"
                        :paymentType="'ClinicBankAccount'" @onClinicBankPaymentSuccess="clinicBankPaymentSuccess">
                      </BankSetup>
                    </vs-tab>
                  </vs-tabs>
                </vs-popup>
                <div class="bg-white flex flex-col roundex-lg shadow-lg rounded-lg">
                  <div class="px-6 p-4">
                    <h4>Payment options</h4>
                  </div>

                  <h5 class="px-6">Selected Payment Methods</h5>
                  <div>
                    <ul>
                      <li v-for="(card, index) in paymentMethods" :key="index">
                        <div class="flex flex-row p-4 px-6">
                          <div class="pr-4 pt-1">
                            <vs-radio v-model="paymentType" :vs-value="
                              card.cardId
                                ? card.cardId
                                : 'SavedClinicBankAccount'
                            " />
                          </div>
                          <div v-if="card.cardId">
                            <p>{{ card.brand }}</p>
                            <p>XXXX XXXX XXXX {{ card.last4 }}</p>
                            <p>
                              {{ card.exp_month }} /
                              {{ card.exp_year }}
                            </p>
                          </div>
                          <div v-if="!card.cardId">
                            <p>{{ card.accountHolderName }}</p>
                            <p>{{ card.accountHolderEmail }}</p>
                            <p>
                              {{ card.bsb }} /
                              {{ card.accountNumber }}
                            </p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="border p-6 mt-5" style="border-top: solid 1px #e0e0e0">
                    <div class="
                        flex flex-row
                        bg-primary
                        p-3
                        rounded-lg
                        text-white
                        cursor-pointer
                      " style="width: 230px" @click="openPopup" v-if="totalPrice <= 29999 * 100">
                      <plus-icon size="1.5x" class="custom-class"></plus-icon>
                      <div class="pl-4 pt-1">Add Payment Method</div>
                    </div>

                    <div class="
                        flex flex-row
                        p-3
                        rounded-lg
                        text-white
                        cursor-pointer
                      " style="width: 230px; background-color: #a0a0a0" v-if="totalPrice > 29999 * 100">
                      <plus-icon size="1.5x" class="custom-class"></plus-icon>
                      <div class="pl-4 pt-1">Add Payment Method</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form-wizard>
        </div>
      </div>
      <div class="w-full lg:w-2/5 md:w-2/5 lg:px-4 md:px-4">
        <div v-if="active === 1">
          <div v-if="hasOrganization" class="w-full bg-white p-5 rounded-lg mb-5 shadow">
            <SwapClinic :title="`This cart is for:`" :isDoctor="true" />
          </div>
          <vx-card>
            <p class="font-semibold mb-3" style="font-size: 16px">
              Price Details
            </p>
            <div class="flex justify-between mb-2">
              <span class="text-grey">Sub Total</span>
              <span>{{ currency }} ${{ subTotal | formatDecimals }}</span>
            </div>
            <div class="flex justify-between mb-2" v-for="discountItem in discountsArray" :key='discountItem._id'>
              <span class="text-grey"> {{ discountItem.rule.discount_title }}</span>
              <span class="text-success">{{ currency }} ${{ discountItem.totalDiscount | formatDecimals }} </span>
            </div>
            <!-- <div class="flex justify-between mb-2">
              <span class="text-grey"> Discount</span>
              <span class="text-success"
                >{{ currency }} {{ discountTotal | formatDecimals }}</span
              >
            </div> -->
            <div class="flex justify-between mb-2">
              <span class="text-grey"> Delivery</span>
              <span class="text-success">{{ currency }} {{ deliveryCharge | formatDecimals }}</span>
            </div>
            <div class="flex justify-between mb-2">
              <span class="text-grey w-3/5">
                2% credit and debit card fee applies</span>
              <span class="text-success">{{ currency }} ${{
                  creditCardTransactionFee | formatDecimals
              }}</span>
            </div>
            <div class="flex justify-between mb-2" v-if="!isUS">
              <span class="text-grey">GST (10%)</span>
              <span>{{ currency }} ${{ gstTotal | formatDecimals }}</span>
            </div>
            <div class="flex justify-between mb-2" v-if="isUS">
              <span class="text-grey">Taxes</span>
              <span>{{ currency }} ${{ tax | formatDecimals }}</span>
            </div>

            <vs-divider />

            <div class="flex justify-between font-semibold mb-3">
              <span>Total</span>
              <span>{{ currency }} ${{ totalPrice | formatDecimals }}</span>
            </div>

            <vs-button class="w-full" :disabled="!validateForm || cartItems.length <= 0"
              @click="onClick(2)">NEXT</vs-button>
          </vx-card>

          <vx-card>
            <p class="font-semibold mb-3">Add Notes</p>
            <vs-textarea data-vv-as="field" name="notes" :maxlength="max" v-model="shippingDetails.notes"
              class="w-full h-20 mt-5"></vs-textarea>
          </vx-card>
        </div>
        <div v-if="active === 2">
          <div v-if="hasOrganization" class="w-full">
            <SwapClinic :title="`This cart is for:`" :isDoctor="true" />
          </div>
          <vx-card :title="shippingDetails.clinicName">
            <div>
              <p>{{ shippingDetails.address.displayAddress }}</p>
            </div>

            <vs-divider />
            <div v-if="deliveryButtonDisabled" class="w-full p-3 text-center rounded text-white"
              style="background-color: #a0a0a0">
              Deliver to this Address
            </div>
            <vs-button v-if="!deliveryButtonDisabled" class="w-full" @click="onClick(3)">Deliver to this
              Address</vs-button>
          </vx-card>
          <vx-card>
            <p class="font-semibold mb-3">Order Notes</p>
            <p class="font-regular mb-3 break-all">
              {{ shippingDetails.notes }}
            </p>
          </vx-card>
        </div>
        <div v-if="active === 3">
          <div v-if="hasOrganization" class="w-full">
            <SwapClinic :title="`This cart is for:`" :isDoctor="true" />
          </div>
          <vx-card>
            <p class="font-semibold mb-3" style="font-size: 16px">
              Price Details
            </p>
            <p style="color: #999999">
              Please note there is a maximum purchase of $30,000 {{ currency }}
            </p>
            <div style="
                width: 100%;
                margin: 15px 0;
                border-bottom: solid 1px #e0e0e0;
              "></div>
            <div class="flex justify-between mb-2">
              <span class="text-grey">Sub Total</span>
              <span>{{ currency }} ${{ subTotal | formatDecimals }}</span>
            </div>
            <div class="flex justify-between mb-2" v-for="discountItem in discountsArray" :key='discountItem._id'>
              <span class="text-grey"> {{ discountItem.rule.discount_title }}</span>
              <span class="text-success">{{ currency }} ${{ discountItem.totalDiscount | formatDecimals }} </span>
            </div>
            <!-- <div class="flex justify-between mb-2">
              <span class="text-grey"> Discount</span>
              <span class="text-success"
                >{{ currency }} ${{ discountTotal | formatDecimals }}</span
              >
            </div> -->
            <div class="flex justify-between mb-2">
              <span class="text-grey"> Delivery</span>
              <span class="text-success">{{ currency }} ${{ deliveryCharge | formatDecimals }}</span>
            </div>
            <div class="flex justify-between mb-2">
              <span class="text-grey">2% Card Transaction Fee</span>
              <span class="text-success">{{ currency }} ${{
                  creditCardTransactionFee | formatDecimals
              }}</span>
            </div>
            <div class="flex justify-between mb-2" v-if="!isUS">
              <span class="text-grey">GST (10%)</span>
              <span>{{ currency }} ${{ gstTotal | formatDecimals }}</span>
            </div>
            <div class="flex justify-between mb-2" v-if="isUS">
              <span class="text-grey">Taxes</span>
              <span>{{ currency }} ${{ tax | formatDecimals }}</span>
            </div>
            <vs-divider />

            <div class="flex justify-between font-semibold mb-3">
              <span>Total</span>
              <span>{{ currency }} ${{ totalPrice | formatDecimals }}</span>
            </div>
            <vs-button v-if="paymentType" class="w-full" @click="sendToServer({ amount: totalPrice })"
              :disabled="totalPrice > 29999 * 100">Confirm And Pay</vs-button>
            <div v-if="!paymentType" class="w-full p-3 text-center rounded text-white"
              style="background-color: #a0a0a0">
              Confirm And Pay
            </div>
          </vx-card>
          <vx-card>
            <p class="font-semibold mb-3">Order Notes</p>
            <p class="font-regular mb-3 break-all">
              {{ shippingDetails.notes }}
            </p>
          </vx-card>
        </div>
      </div>
    </div>
    <vs-popup class="holamundo" title="Outstanding Payment" :active.sync="popupActive2">
      <div class="flex flex-no-wrap">
        <div class="w-1/6 flex items-center justify-center">
          <vs-icon icon="clear" size="medium" bg="#f9d6d6" color="#b21f1e" round></vs-icon>
        </div>
        <div class="w-5/6">
          <p>
            Sorry! Your account has an outstanding product payment. Please
            complete your payment before continuing.
            <br />Need help? Call 1300 375 646
          </p>
        </div>
      </div>
      <div class="flex justify-between mt-5">
        <vs-button class="mr-4 mb-4" @click="popupActive2 = false">
          Close
        </vs-button>
        <vs-button class="mr-4 mb-4" @click="goToOrders()">
          Go to payment
        </vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { mapActions } from "vuex";
import _ from "lodash";
const CheckoutListView = () => import("./CheckoutListView.vue");
import StripeElements from "../../components/order-center/StripeElements";
import ClinicBankDetailSetup from "../../clinic/bankDetails/Setup";
import BankSetup from "../../components/order-center/BankSetup";
import Tabs from "../../../layouts/components/tabs/tabs.vue";
import { PlusIcon, XIcon, } from "vue-feather-icons";
import SwapClinic from "../../components/shop/clinic.vue";
import StoreHelper from "../../../mixins/Store.vue";
import CheckoutHelper from "../../../mixins/Checkout.vue";

export default {
  name: "Checkout",
  mixins: [StoreHelper, CheckoutHelper],
  data() {
    return {
      cartItems:[],
      disableInput:false,
      hasOrganization: true,
      stripePublishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
      saveCreditCard: true,
      isSavedAddress: false,
      selectedMode: null,
      cardDetails: [],
      defaultCard: null,
      financialDetails: null,
      isNewClinicBankAccount: true,
      isAccessPaymentTerms: false,
      paymentMode: null,
      bankMode: null,
      popupActive: false,
      role: null,
      shippingDetails: {
        clinicName: "",
        address: {},
        email: "",
        notes: "",
      },
      card: {
        number: "",
        cvc: "",
        exp: "",
      },
      isCreditCard: false,
      max: 200,
      // TAB 3
      paymentType: null,
      popupActive2: false,
      // cvv: '',
      active: 1,
      operations: {
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
      },
      deliveryButtonDisabled: true,
      isUS: true,
      currency: process.env.VUE_APP_CURRENCY,
      region: process.env.VUE_APP_REGION,
      paymentMethods: [],
      tax: 0
    };
  },
  computed: {
    // cartItems() {
    //   return this.$store.state.eCommerce.cartItems.slice().reverse();
    // },
    totalPriceDiscountAndDeliveryCharge() {
      let discount = 0;
      this.discountsArray.forEach(discountItem => {
        discount += discountItem.totalDiscount;
      })
      return (
        this.$store.getters["eCommerce/totalCarPrice"] - discount + this.deliveryCharge
      );
    },
    totalPrice() {
      const creditCardFee = this.creditCardTransactionFee;
      let total = this.totalPriceDiscountAndDeliveryCharge + creditCardFee;
      if (this.region === "AU") {
        total = total + this.gstTotal;
      }
      return total + this.tax;
    },
    gstTotal() {
      // return ((this.totalPriceDiscountAndDeliveryCharge + this.creditCardTransactionFee) *10)/100;
      let gst =
        ((this.totalPriceDiscountAndDeliveryCharge +
          this.creditCardTransactionFee) *
          10) /
        100;
      return gst;
    },
    deliveryCharge() {
      return this.$store.getters["eCommerce/delivery"];
    },
    creditCardTransactionFee() {
      if (
        this.paymentType &&
        ![
          "ClinicBankAccount",
          "SavedClinicBankAccount",
          "PaymentTermsInvoice",
        ].includes(this.paymentType)
      ) {
        // return this.totalPriceDiscountAndDeliveryCharge * 2 / 100;
        const creditCardCharge =
          (this.totalPriceDiscountAndDeliveryCharge * 2) / 100;
        return creditCardCharge;
      }
      return 0;
    },
    discountTotal() {
      return (
        this.subTotal +
        this.deliveryCharge +
        this.creditCardTransactionFee +
        this.gstTotal -
        this.totalPrice
      );
    },
    subTotal() {
      return this.$store.getters["eCommerce/subTotalCartPrice"];
    },
    isInWishList() {
      return (itemId) => this.$store.getters["eCommerce/isInWishList"](itemId);
    },
    validateForm() {
      return !this.errors.any();
    },
  },
  async created() {
    this.getClinicPaymentDetails();
    const userDetail = await this.getUserDetail();
    this.isUS = process.env.VUE_APP_REGION === "US";
    this.shippingDetails = userDetail;
    if (this.$store.state.AppActiveUser.defaultPaymentMethod) {
      this.paymentType =
        this.$store.state.AppActiveUser.defaultPaymentMethod === "CreditCard"
          ? "SavedCard"
          : "SavedClinicBankAccount";
    }
    if (this.isUS)
      this.getUSTax()
  },
  watch: {
    // paymentType: function(val) {
    //   if (
    //     ![
    //       "CreditCard",
    //       "SavedCard",
    //       "ClinicBankAccount",
    //       "SavedClinicBankAccount",
    //       "PaymentTermsInvoice"
    //     ].includes(val) &&
    //     val
    //   ) {
    //     this.paymentType = "StoredCard";
    //   }
    // },
    "$store.state.eCommerce.cartItems": {
      handler(val) {
        this.cartItems=JSON.parse(JSON.stringify(val.slice().reverse()))
      },
      immediate: true
    },
    operations: {
      deep: true,
      handler(value) {
        const isDisabled = Object.keys(value).filter(
          (k) => !value[k] || value[k] === ""
        );
        if (isDisabled.length <= 0) {
          this.deliveryButtonDisabled = false;
        } else {
          this.deliveryButtonDisabled = true;
        }
      },
    },
    cartItems: function (val) {
      this.deliveryButtonDisabled = val.length > 0 ? false : true;
    },
    popupActive: function (val) {
      console.log(val);
      if (!val && !this.isCreditCard) {
        if (this.$store.state.AppActiveUser.defaultPaymentMethod) {
          this.paymentType =
            this.$store.state.AppActiveUser.defaultPaymentMethod ===
              "CreditCard"
              ? "SavedCard"
              : "SavedClinicBankAccount";
        } else {
          this.paymentType = null;
        }
      }
    },
    "$store.state.clinicId": function () {
      this.updateHoursOfOPeration(this.$store.state.operations);
      this.getClinicPaymentDetails();
      if (this.$store.state.clinic && this.$store.state.clinic.length) {
        this.shippingDetails = this.$store.state.clinic[0];
      }

      if (this.cartItems.length && (this.active === 3 || this.active === 2)) {
        this.active = 1;
      }
      this.isUS = process.env.VUE_APP_REGION === "US";
      if (this.isUS)
        this.getUSTax()
    },
  },
  methods: {
    ...mapActions("ecommerce", ["getCartItemsFromApi", "paymentForCartItems"]),
    ...mapActions("general", [
      "fetchUserDetails",
      "fetchCardDetails",
      "fetchCardDetailsList",
      "fetchClinicPaymentDetails",
      "fetchAccountPaymentDetailsList",
      "taxJarCalculateOrderTax"
    ]),
    getUSTax() {
      const data = {
        "from_country": "US",
        "from_zip": this.shippingDetails.address.postCode,
        "from_state": this.shippingDetails.address.state,
        "from_city": this.shippingDetails.address.suburb,
        "from_street": this.shippingDetails.address.displayAddress,
        "amount": this.totalPrice / Math.pow(10, 2),
        "shipping": "0",
        "to_country": "US",
        "to_zip": this.shippingDetails.address.postCode,
        "to_state": this.shippingDetails.address.state,
        "to_city": this.shippingDetails.address.suburb,
        "to_street": this.shippingDetails.address.displayAddress
      }
      console.log('addresss', this.shippingDetails)
      this.taxJarCalculateOrderTax(data)
        .then((res) => {
          console.log('ress', res.data.data.tax.amount_to_collect)
          this.tax = res.data.data.tax.amount_to_collect * 100
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getClinicPaymentDetails() {
      if (sessionStorage.getItem("doctorClinicSelectedClinic")) {
        this.fetchAccountPaymentDetailsList({
          id: this.$store.state.AppActiveUser._id,
          idType: "basic",
        })
          .then((res) => {
            console.log(res, "values");
            this.paymentMethods = res.data.data;
            const result = this.paymentMethods.filter((val) => val.isDefault);
            if (this.paymentMethods.length > 0 && result.length > 0) {
              this.paymentType = result[0].cardId
                ? result[0].cardId
                : "SavedClinicBankAccount";
            } else {
              this.paymentType = null
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    updateHoursOfOPeration(val) {
      if (val && Object.keys(val).length) {
        this.operations = val;
      }
    },
    onClick(val) {
      if (this.cartItems.length <= 0 && val === 2) {
        this.active = 1;
      } else if (!this.deliveryButtonDisabled && val === 3) {
        this.active = val;
      } else if (val !== 3) {
        this.active = val;
      }
    },
    clinicBankPaymentSuccess(message = null) {
      console.log(message, "message value");
      if (message && message === "OUTSTANDING_PAYMENT") {
        this.$vs.loading.close();
        this.popupActive2 = true;
        return;
      } else {
        this.popupActive = false;
      }
    },
    openPopup() {
      this.popupActive = true;
      this.paymentType = "CreditCard";
    },
    goToOrders() {
      this.popupActive2 = false;
      path = "/org-owner/orders";
      setTimeout(() => {
        this.$router.push({ path });
      }, 200);
    },
    submit() {
      this.$refs.stripeElement.submit();
      this.isCreditCard = true;
      this.popupActive = false;
    },
    tokenCreated(token) {
      this.token = token;
      // for additional charge objects go to https://stripe.com/docs/api/charges/object
      this.charge = {
        source: token.id,
        amount: this.totalPrice, // the amount you want to charge the customer in cents. $100 is 1000 (it is strongly recommended you use a product id and quantity and get calculate this on the backend to avoid people manipulating the cost)
      };
      this.sendToServer(this.charge);
    },
    async sendToServer(charge) {
      // Send to charge to your backend server to be processed
      this.$vs.loading();
      var type = null;
      if (
        this.paymentType == "CreditCard" ||
        this.paymentType == "SavedCard" ||
        this.paymentType == "ClinicBankAccount" ||
        this.paymentType == "SavedClinicBankAccount" ||
        this.paymentType == "PaymentTermsInvoice"
      ) {
        type = this.paymentType;
      } else if (this.paymentType) {
        type = "StoredCard";
      }
      // Documentation here: https://stripe.com/docs/api/charges/create
      await this.paymentForCartItems({
        charge: charge,
        shippingDetails: this.shippingDetails,
        paymentType: type,
        cardId: type === "StoredCard" ? this.paymentType : null,
        operations: this.operations,
      })
        .then((response) => {
          this.$vs.notify({
            title: response.data.title,
            text: response.data.message,
            color: "success",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
          this.$vs.loading.close();
          const name = "DoctorClinicOrderComplete";
          this.$router.push({
            name,
            query: {
              orderNumber: response.data.data.newOrder.orderNumber,
              orderId: response.data.data.newOrder._id,
            },
          });
        })
        .catch((error) => {
          if (error.data.title === "OUTSTANDING_PAYMENT") {
            this.$vs.loading.close();
            this.popupActive2 = true;
            return;
          } else {
            this.$vs.loading.close();
            this.$vs.notify({
              title: error.data.title,
              text: error.data.message,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle",
            });
          }
          let name = "OrgOwnerOrderList";
          setTimeout(() => {
            this.$router.push({ name });
          }, 1000);
        });
    },
    checkItemQuantity(quantity, data) {
      if (this.quantity < data.inventory.requiredQuantity) {
        this.errors.add({
          field: "MinimumQuantityLimit",
          msg:
            "Minimum order quantity of " +
            data.name +
            " is " +
            data.inventory.requiredQuantity,
          id: data._id,
        });
      } else {
        this.errors.remove("MinimumQuantityLimit");
      }
    },
    async nextTab() {
      let isValid = await this.$validator.validateAll("basic");
      if (!isValid) {
        this.$vs.notify({
          title: "Error",
          text: "Validation Failed",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
        return false;
      }
      this.$refs.checkoutWizard.nextTab();
      // if (this.$refs.checkoutWizard.activeTabIndex == 2) {
      //   this.paymentType = "CreditCard";
      // }
    },
    savedClinicBankPayment() {
      this.$vs.loading();

      this.paymentForCartItems({
        charge: this.totalPrice,
        shippingDetails: this.shippingDetails,
        paymentType: "SavedClinicBankAccount",
      })
        .then((res) => {
          this.active1 = true;
          this.$vs.notify({
            title: res.data.title,
            text: res.data.message,
            color: "success",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
          this.$router.push({
            name: "ClinicOrderComplete",
            query: {
              orderNumber: res.data.data.newOrder.orderNumber,
              orderId: res.data.data.newOrder._id,
            },
          });
          this.$vs.loading.close();
        })
        .catch((err) => {
          console.log("err", err);
          this.$vs.loading.close();
          const errMessage =
            err.data && err.data.message
              ? err.data.message
              : err.message
                ? err.message
                : "Oops ! something went wrong. Please try later.";

          // this.$vs.notify({
          //   title: "Error",
          //   text: errMessage,
          //   color: "danger",
          // });
        });
    },
    async getUserDetail() {
      const userDetail = await this.fetchUserDetails();
      const cardDetails = await this.fetchCardDetails();
      if (
        userDetail.data.data.paymentDetails &&
        userDetail.data.data.paymentDetails.cardId
      ) {
        this.defaultCard = cardDetails.data.data;
      }
      const cardDetailsList = await this.fetchCardDetailsList();
      this.cardDetails = cardDetailsList.data.data;
      this.isAccessPaymentTerms =
        typeof userDetail.data.data.isAccessPaymentTerms !== "undefined"
          ? userDetail.data.data.isAccessPaymentTerms
          : false;

      if (userDetail.data.data.financialDetails) {
        this.financialDetails = userDetail.data.data.financialDetails;
        this.isNewClinicBankAccount = false;
      }
      if (userDetail.data.data.clinicOpeningHours) {
        const sched = { ...userDetail.data.data.clinicOpeningHours };
        delete sched._id;
        this.operations = {
          monday: sched.mondays,
          tuesday: sched.tuesdays,
          wednesday: sched.wednesdays,
          thursday: sched.thursdays,
          friday: sched.fridays,
          saturday: sched.saturdays,
          sunday: sched.sundays,
        };
      }

      return this.formatUserDetailResponse(userDetail.data.data);
    },
    formatUserDetailResponse(userDetail) {
      return {
        clinicName: userDetail.clinicName,
        address: userDetail.address,
        email: userDetail.email,
      };
    },
    // TAB 1
    removeItemFromCart(item) {
      this.$store.dispatch("eCommerce/toggleItemInCart", item);
    },
    wishListButtonClicked(item) {
      // Toggle in Wish List
      if (this.isInWishList(item.objectID))
        this.$router.push("/apps/eCommerce/wish-list").catch(() => { });
      else {
        this.toggleItemInWishList(item);
        this.removeItemFromCart(item);
      }
    },
    toggleItemInWishList(item) {
      this.$store.dispatch("eCommerce/toggleItemInWishList", item);
    },
    increase(item) {
      return _.debounce(()=>{

        // Disable input first
        this.disableInput=true;

        setTimeout(()=>{
          this.disableInput = false
        },0)
        // Declare variables
        let cloneCart = _.cloneDeep(this.cartItems);
        let index = _.findIndex(cloneCart, { _id: item._id });

        // Checking if required quantity is met
        if (item.quantity < item.inventory.requiredQuantity) {
          this.errors.add({
            field: "MinimumQuantityLimit",
            msg:
              "Minimum quantity for order is " +
              item.inventory.requiredQuantity,
            id: item._id,
          });
          return;
        }

         // 1. Check if its emtpy
        // 2. Check if its less than 1
        if(!cloneCart[index].quantity || cloneCart[index].quantity<1){
          this.cartItems[index].quantity = 1
          item.quantity = 1
          cloneCart[index].quantity = 1
          this.errors.add({
            field: "MinimumQuantityLimit",
            msg: "Minimum quantity for order is 1",
            id: item._id,
          });
        }

        // Check if its more than 999
        if(cloneCart[index].quantity>999){
          this.cartItems[index].quantity = 999
          cloneCart[index].quantity = 999
          this.errors.add({
            field: "MaximumQuantityLimit",
            msg: "Maximum quantity for order is 999",
            id: item._id,
          });
        }

        this.errors.removeById(item._id);
        this.$store.dispatch("eCommerce/updateItemQuantity", {
          item: item,
        });
      }, 500)()

    },
    decrease(_id) {
      let cloneCart = _.cloneDeep(this.cartItems);
      let index = _.findIndex(cloneCart, { _id });

      // cloneCart[index].quantity = cloneCart[index].quantity - 1;

      let item = cloneCart[index];

      if (item.quantity < item.inventory.requiredQuantity) {
        this.errors.add({
          field: "MinimumQuantityLimit",
          msg:
            "Minimum quantity for order is " + item.inventory.requiredQuantity,
          id: item._id,
        });
      } else {
        console.log("quantity", item.quantity);
        this.errors.removeById(item._id);
        this.$store.dispatch("eCommerce/updateItemQuantity", {
          item: item,
        });
      }
    },
  },
  components: {
    BankSetup,
    ClinicBankDetailSetup,
    CheckoutListView,
    FormWizard,
    TabContent,
    StripeElements,
    Tabs,
    PlusIcon,
    XIcon,
    SwapClinic,
  },
  beforeMount: async function () {
    if (
      localStorage.getItem(`${process.env.VUE_APP_PROCESS_TOKEN}OrganizationId`)
    ) {
      this.hasOrganization = true;
    }

    this.role = localStorage.getItem(
      `${process.env.VUE_APP_PROCESS_TOKEN}Role`
    );
  },
};
</script>

<style lang="scss" scoped>
#ecommerce-checkout-demo {
  .increment-buttons {
    height: 42px;
    background: #3cc9b230;
    width: 100px;
  }

  .item-view-primary-action-btn {
    color: #2c2c2c !important;
    background-color: #f6f6f6;
  }

  .item-name {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
  }

  .vue-form-wizard {
    padding-bottom: 0;

    ::v-deep .wizard-header {
      padding: 0;
    }

    ::v-deep .wizard-tab-content {
      padding-right: 0;
      padding-left: 0;
      padding-bottom: 0;

      .wizard-tab-container {
        margin-bottom: 0 !important;
      }
    }
  }

  @media (min-width: 1024px) {
    .increment-buttons {
      border-radius: 5px;
    }

    .remove-button {
      border-radius: 5px;
    }
  }
}
</style>
